#toolbar {
  height: $toolbarHeight;
  min-height: $toolbarHeight;
  max-height: $toolbarHeight;
  z-index: 55 !important;

  #navigation-toggle {
    width: $toolbarHeight;
    min-width: $toolbarHeight;
    height: $toolbarHeight;
    margin: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0;
  }

  #notifications-menu {
    height: $toolbarHeight;

    .notifications-button {
      min-width: $toolbarHeight;
      height: $toolbarHeight;
      margin: 0;
      padding: 0 16px;
      border-radius: 0;

      .angular-notifications-icon {
        right: -8px;
        top: -8px;
        min-width: 20px;
        height: 20px;
      }
    }
  }

  #user-menu {
    height: $toolbarHeight;
    padding: 0;

    md-menu-item .md-button {
      font-size: 15px;

      md-icon {
        margin: auto 16px auto 0;
      }
    }

    md-menu-content {
      &.md-menu-bar-menu {
        &.md-dense {
          padding: 8px 0;

          .md-button,
          md-menu-item {
            height: 48px;
            line-height: 48px;
          }
        }
      }
    }

    .user-button {
      margin: 0;
      padding: 0 16px;
      height: $toolbarHeight;
      text-transform: none;

      .avatar-wrapper {
        position: relative;

        .status {
          position: absolute;
          bottom: -3px;
          right: 5px;
        }
      }

      .username {
        margin: 0 12px 0 8px;
      }
    }
  }

  #language-menu {
    height: $toolbarHeight;

    .language-button {
      min-width: $toolbarHeight;
      height: $toolbarHeight;
      margin: 0;
      padding: 0 16px;
      border-radius: 0;

      .iso {
        text-transform: uppercase;
      }

      .flag {
        margin-right: 8px;
      }
    }
  }

  #quick-panel-toggle {
    width: $toolbarHeight;
    height: $toolbarHeight;
    margin: 0;
    border-radius: 0;
  }

  .toolbar-separator {
    height: $toolbarHeight;
    width: 1px;
    background: rgba(0, 0, 0, 0.12);
  }
}

.mark__all__read {
  height: 2opx;
  text-align: right;
}

.mark__all__read button {
  text-align: right;
  cursor: pointer;
  color: rgb(40, 0, 151);
  margin: 15px 5px 0px 0px;
}

#language-menu-content {
  md-menu-item {
    .flag {
      margin-right: 16px;
    }
  }
}

#language-message {
  min-height: 88px;
  max-width: 600px;

  .md-toast-content {
    height: 90px;
  }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {
  #toolbar {
    #user-menu {
      .user-button {
        min-width: 64px;
        padding: 0 2px 0 10px;
      }
    }
  }
}
