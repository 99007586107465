$numeric-icon-size: 54px;
$numeric-icon-size-medium: 40px;
.container { 
    /*margin-top: 10px; */
}
  
/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
    numeric-card {
        md-icon {
            font-size: $numeric-icon-size-medium!important;
            width: $numeric-icon-size-medium!important;
            height: $numeric-icon-size-medium!important;
            min-width: $numeric-icon-size-medium!important;
            min-height: $numeric-icon-size-medium!important;
            line-height: $numeric-icon-size-medium!important;
        }
        h2 {
            font-size: 2.2rem;
            span {
                font-size: 1.7rem;
            }
        }
        p {
            font-size: 1.4rem;
        }
        .timestamp{
            font-size: 11px;
        }
    }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 992px) {
    numeric-card {
        h2 {
            font-size: 2rem;
            span {
                font-size: 1.5rem;
            }
        }
        p {
            font-size: 1.3rem;
        }
    }
}