#profile {
	#myProfile {
		
		
		.header {
			background: rgb(3,155,229);
			
			.user-info {
				
				> div {
					padding:0 8px;
				}
				
				
			}
			
		}
		
		.content {
			
			md-content {
				background-color: transparent;
				
				.profile-box {
					p.profile-image-import {
						#image-file {
							display:none;
						}
					}
				}
			}
		}
		
		form {
			width: auto;
			margin: inherit;
			font-size:inherit;
			
			// Silly Hack for disabled form input's
			[disabled] md-input-container.md-default-theme .md-input, [disabled] md-input-container .md-input, md-input-container.md-default-theme .md-input[disabled], md-input-container .md-input[disabled] {
				color: rgba(0,0,0,0.7);
				cursor:not-allowed;
			}
		}
		
		
			
		
	}
}


// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

	#profile {
	
		#myProfileForm {
			
			.header {
				
				.user-info {}
				
			}
		}
	}
}







/*

#profile {

    .header {
        height: 220px;
        min-height: 220px;
        max-height: 220px;
		background: rgb(3,155,229);
        //background: url('/assets/images/backgrounds/march.jpg') no-repeat 0 45%;
        //background-size: 100% auto;

        .profile-image {
            margin-right: 24px;
        }

        .name {
            font-size: 34px;
            color: #FFFFFF;
        }

        .actions {

            .md-button {
                text-transform: none;
                padding: 0 16px;
                height: 32px;
                line-height: 32px;
                margin: 0 0 0 8px;
            }
        }
    }

    .content {

        md-content {
            background-color: transparent;
        }
    }

    // Profile boxes
    .profile-box {
        margin-bottom: 16px;

        header {
            padding: 16px;

            .title {
                font-size: 17px;
            }

            .more {
                cursor: pointer;
            }
        }

        .content {
            padding: 16px;
            background-color: #FFF;
        }

        footer {
            padding: 8px;
            border-top: 1px solid rgba(0, 0, 0, 0.08);
            background-color: rgba(0, 0, 0, 0.06);
        }

        &.info-box {

            .info-line {
                margin-bottom: 24px;

                .title {
                    font-size: 15px;
                    font-weight: 500;
                    padding-bottom: 4px;
                }

                .info {

                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}


// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

    #profile {

        .header {

            .profile-image {
                margin: 0 0 16px 0;
            }

            .name {
                margin-bottom: 32px;
            }
        }
    }

}
.modal-body {
    padding-top: 20px;
  }
  
  .modal-header {
      background: #f8f9fa !important;
      padding-top: 10px;
      padding-bottom: 10px;
  }
  
.modal-open {
    overflow: hidden; }
    .modal-open .modal {
      overflow-x: hidden;
      overflow-y: auto; }
  
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0; }
  
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none; }
    .modal.fade .modal-dialog {
      transition: transform 0.3s ease-out;
      transform: translate(0, -25%); }
      @media screen and (prefers-reduced-motion: reduce) {
        .modal.fade .modal-dialog {
          transition: none; } }
    .modal.show .modal-dialog {
      transform: translate(0, 0); }
  
  .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2)); }
    .modal-dialog-centered::before {
      display: block;
      height: calc(100vh - (0.5rem * 2));
      content: ""; }
  
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #e9ecef;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    outline: 0; }
  
  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000; }
    .modal-backdrop.fade {
      opacity: 0; }
    .modal-backdrop.show {
      opacity: 0.5; }
  
  .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1.5rem;
    border-bottom: 0 solid #e9ecef;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem; }
    .modal-header .close {
      padding: 1.5rem;
      margin: -1.5rem -1.5rem -1.5rem auto; }
  
  .modal-title {
    margin-bottom: 0;
    line-height: 1.5; }
  
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1.5rem; }
  
  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1.5rem;
    border-top: 0 solid #e9ecef; }
    .modal-footer > :not(:first-child) {
      margin-left: .25rem; }
    .modal-footer > :not(:last-child) {
      margin-right: .25rem; }
  
  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll; }
  
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto; }
    .modal-dialog-centered {
      min-height: calc(100% - (1.75rem * 2)); }
      .modal-dialog-centered::before {
        height: calc(100vh - (1.75rem * 2)); }
    .modal-sm {
      max-width: 300px; } }
  
  @media (min-width: 992px) {
    .modal-lg {
      max-width: 800px; } }
	  
*/