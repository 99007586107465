/*
$battery-icon-size: 54px;
$battery-icon-size-medium: 40px;


md-switch.md-default-theme.md-checked .md-thumb, md-switch.md-checked .md-thumb {
  background-color:white;
}

md-switch.md-default-theme.md-checked .md-bar, md-switch.md-checked .md-bar {
  background-color:rgb(72,178,198) !important;
}

.colorwhite {
  color: rgb(72,178,198);
}

.colorgrey {
  color: rgb(158,158,158);
}

.at-multirange-slider {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    color: #999999;
    height: 1em;
    padding-top: .5em;
  }
  
  .at-multirange-slider .slider {
    border: 1px solid silver;
    height: 0.5em;
    position: relative;
  }
  
  .at-multirange-slider .slider-range {
    box-sizing: border-box;
    display: inline-block;
    height: 100%;
    position: relative;
    vertical-align: top;
  }
  
  .at-multirange-slider .slider-handle {
    border-radius: 1em;
    background: #fff;
    cursor: pointer;
    border: 2px solid gray;
    height: 1em;
    position: absolute;
    right: -0.4em;
    text-align: center;
    top: -0.3em;
    width: 1em;
    z-index: 100;
  }
  
  .at-multirange-slider .slider-handle:hover {
    background: #ddd;
  }
  
  .at-multirange-slider .slider-range:last-child .slider-handle{
      display: none;
  }
  
  .at-multirange-slider .slider-label-top {
    font-size: .75em;
    display: block;
    position: absolute;
    text-align: center;
    top:-1.5em;
    width:100%;
  }
  .at-multirange-slider .slider-label-bottom {
    font-size: .75em;
    display: block;
    line-height: 0.7em;
    position: absolute;
    text-align: center;
    top:1.3em;
    width:100%;
  }
  
  .at-multirange-slider .slider-handle .slider-handle-label {
    font-size: .75em;
    position: absolute;
    right: -0.4em;
    text-align: center;
    top: 3em;
  }
  
  .slider {
      background: #022;
    }
    
  
  .color-range-2 {
    background-color: #fff;
  }
  .color-range-1 {
    background-color: #cecc26;
  }
  .color-range-0 {
    background-color: #fff;
  }



@media only screen and (max-width : 1200px) {
    battery-card {
        md-icon {
            font-size: $battery-icon-size-medium!important;
            width: $battery-icon-size-medium!important;
            height: $battery-icon-size-medium!important;
            min-width: $battery-icon-size-medium!important;
            min-height: $battery-icon-size-medium!important;
            line-height: $battery-icon-size-medium!important;
        }
        h2 {
            font-size: 2.2rem;
            span {
                font-size: 1.7rem;
            }
        }
        p {
            font-size: 1.4rem;
        }
        .timestamp{
            font-size: 11px;
        }
    }
}


@media only screen and (max-width : 992px) {
    battery-card {
        h2 {
            font-size: 2rem;
            span {
                font-size: 1.5rem;
            }
        }
        p {
            font-size: 1.3rem;
        }
    }
}

  */