#events {
	
	table.md-table tbody.md-body > tr.md-row,
	table.md-table tfoot.md-foot > tr.md-row {
		cursor: pointer;
		&:hover {
			background-color: #fafafa;
		}
	}


	table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2),
	table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2) {
		padding: 0 24px 0 0;
	}
	
	.top-toolbar {
        padding: 12px 16px 15px 16px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }
	
	.daterange-wrapper {
        
        position: relative;
        min-width: 256px;
        
        .daterange {
            height: 34px;
            line-height: 34px;
            padding: 5px;
            font-weight: 500;

            .icon {
                color: rgb(33,33,33);
            }

            input {
                background: #fafafa;
                padding-left: 12px;
                height: 34px;
                color: rgb(33,33,33);
				font-size:100%;
            }
            
        }
        
    }
}