#customers {
		
	.search-wrapper {
		.search {
			padding: 6px 15px 6px 30px;
			margin: 3px;
			background: none;
		}
	}
	
	table {
		.vertical-divider {
			margin: 12px 16px;
			display: inline-block;
			border-top-width: 0;
			border-right-width: 1px;
			border-right-color: rgba(0,0,0,.12);
			border-right-style: solid;
			height: 32px;
		}
		
		th:last-child {
			text-align: center;
			width: 132px;
			white-space: nowrap;
		}
		
		td:last-child {
			width: 132px;
			white-space: nowrap;
		}
	}
	
}