/*----------------------------------------------------------------*/
/*  DELTA Custom styles
/*----------------------------------------------------------------*/


#layout-vertical-navigation {
	#vertical-navigation {
		.ms-navigation {
			.ms-navigation-node {
				.ms-navigation-item {
					> {
						.ms-navigation-button {
							font-weight: 400;
							
							&.active {
								font-weight: 600;
							}
						}
					}
				}
			}
		}
	}
}

.page-layout {
	&.simple {
		&.right-sidenav {
			.center {
				box-shadow: none;
				
				> {
					.breadcrumb {
						padding:16px;
						
						.icon {
							margin-right:8px;
							
							&.sidenav-toggle {
								cursor: pointer;
							}
						}
						
						.item {
							font-weight:400;
							font-style: italic;
						}
					}
					
					.content {
						background-color: transparent;
						padding: 0 16px 16px 16px;
						
						.grid-stack {
							
							&.show-grid {
								/* background-color: #eeeeee; */
								background-image: linear-gradient(#e0e0e0 1px, transparent 1px), linear-gradient(90deg, #e0e0e0 1px, transparent 0px), linear-gradient(rgba(255,255,255,.3) 5px, transparent 100px), linear-gradient(90deg, rgba(255,255,255,.3) 5px, transparent 100px);
								background-size: 90px 70px, calc(8.33% + 0px) 100px, 20px 20px, 20px 20px;
								background-position: -2px -2px, -1px -2px, -1px -1px, -1px -1px;
							}
							
							> {
								.grid-stack-item {
									> {
										.grid-stack-item-content {
											left: 5px;
											right: 5px;
											top: 5px;
											bottom: 5px;
											&.ms-widget-front {
												
												.widget-header {}
												
												.widget-content {
													height: 100%;
													
													&.with-header {
														height: calc(100% - 45px);
													}
													
													.full-height {
														height: 100%
													}
												}
												
											}
										}
									}
								}
							}
						}
						
					}
				}
			}
			
			.sidenav {
				padding: 0;
				
				&.md-locked-open {
					background-color: rgb(245,245,245);
					border: 1px solid rgb(220,220,220);
					width: 250px;
					min-width: 250px;
					max-width: 250px;
				}
				
				.toolbar {
					background-color: transparent;
					color: rgba(0,0,0,0.87);
					border-bottom: 1px solid rgb(220,220,220);
					
					md-icon {
						color: rgba(0,0,0,0.87);
						fill: rgba(0,0,0,0.87);
					}
					
				}
				
				.content {
					.search-wrapper {
						input {
							width: 100%;
							height: 32px;
							min-height: 32px;
							padding: 0 8px;
							border-radius: 8px;
							border: 1px solid #FE7E1D;
						}
					}
				}
				
			}
		}
		&.fullwidth {
			> {
				.breadcrumb {
					padding:16px;
					.icon {
						margin-right:8px;
					}
					
					.item {
						font-weight:400;
						font-style: italic;
					}
				}
				
				.content {
					padding: 0 16px 16px 16px;
					
					.grid-stack {
						
						&.show-grid {
							/* background-color: #eeeeee; */
							background-image: linear-gradient(#e0e0e0 1px, transparent 1px), linear-gradient(90deg, #e0e0e0 1px, transparent 0px), linear-gradient(rgba(255,255,255,.3) 5px, transparent 100px), linear-gradient(90deg, rgba(255,255,255,.3) 5px, transparent 100px);
							background-size: 90px 70px, calc(8.33% + 0px) 100px, 20px 20px, 20px 20px;
							background-position: -2px -2px, -1px -2px, -1px -1px, -1px -1px;
						}
						
						> {
							.grid-stack-item {
								> {
									.grid-stack-item-content {
										left: 5px;
										right: 5px;
										top: 5px;
										bottom: 5px;
										&.ms-widget-front {
											
											.widget-header {}
											
											.widget-content {
												height: 100%;
												
												&.with-header {
													height: calc(100% - 45px);
												}
												
												.full-height {
													height: 100%
												}
											}
											
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}


@media only screen and (max-width : 768px) {
	.page-layout {
		&.simple {
			&.fullwidth {
				> {
					.content {
						.grid-stack {
							> {
								.grid-stack-item {
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}


/*----------------------------------------------------------------*/
/*  Delta Backgrounds
/*----------------------------------------------------------------*/

.full-background {
	height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #FAFAFA;
	
	&.Jan {
        background-image: url('/assets/images/backgrounds/january.jpg');
        background-position: 0 45%;
    }
    &.Feb {
        background-image: url('/assets/images/backgrounds/february.jpg');
        background-position: 0 50%;
    }
    &.Mar {
        background-image: url('/assets/images/backgrounds/march.jpg');
        background-position: 0 45%;
    }
    &.Apr {
        background-image: url('/assets/images/backgrounds/april.jpg');
        background-position: 0 48%;
    }
    &.May {
        background-image: url('/assets/images/backgrounds/may.jpg');
        background-position: 0 47%;
    }
    &.Jun {
        background-image: url('/assets/images/backgrounds/june.jpg');
        background-position: 0 48%;
    }
    &.Jul {
        background-image: url('/assets/images/backgrounds/july.jpg');
        background-position: 0 3%;
    }
    &.Aug {
        background-image: url('/assets/images/backgrounds/august.jpg');
        background-position: 0 61%;
    }
    &.Sep {
        background-image: url('/assets/images/backgrounds/september.jpg');
        background-position: 0 58%;
    }
    &.Oct {
        background-image: url('/assets/images/backgrounds/october.jpg');
        background-position: 0 50%;
    }
    &.Nov {
        background-image: url('/assets/images/backgrounds/november.jpg');
        background-position: 0 46%;
    }
    &.Dec {
        background-image: url('/assets/images/backgrounds/december.jpg');
        background-position: 0 43%;
    }
}


/*----------------------------------------------------------------*/
/*  Delta Dev Helpers
/*----------------------------------------------------------------*/


.border-red {
    border: 1px solid red;
}

.border-blue {
    border: 1px solid blue;
}

.border-green {
    border: 1px solid green;
}

.border-orange {
    border: 1px solid orange;
}

.border-purple {
    border: 1px solid purple;
}

.border-yellow {
    border: 1px solid yellow;
}



