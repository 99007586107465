#profile {
	.usersettings-tab {
		.settings {
			
			.content {
				.slots {
					.slot {
						min-width: 34px;
						background-color: rgba(211,211,211,0.5);
						border-radius: 2px;
						margin: 2px;
						padding: 3px;
						font-size: 10px;
						color: #666;
						text-align:center;
						cursor:pointer;
						
						&.green {
							background-color: rgba(144,238,144,0.5);
						}
					}
				}
			}
			
		}
		
		.settings-sidebar {
			
		}
	}
}

/*

#profile {

    .about-tab {
       // max-width: 1200px;

        .about {
            padding: 8px;

            .general {

                .location {

                    md-icon {
                        line-height: 13px !important;
                    }
                }
            }

            .work {

                .job {

                    .company {
                        padding: 0 16px 0 0;
                        font-weight: 500;
                    }

                    .date {
                        color: rgba(0, 0, 0, 0.54);
                    }
                }
            }
        }

        .about-sidebar {
            padding: 8px 8px 8px 32px;

            .friends {

                .content {

                    .friend {
                        padding: 4px;
                    }
                }
            }

            .groups {

                .content {

                    .group {
                        margin-bottom: 16px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .logo {
                            border: 1px solid rgba(0, 0, 0, 0.12);
                            margin-right: 16px;
                        }

                        .name {
                            font-weight: 500;
                            font-size: 15px;
                        }

                        .category,
                        .members {
                            color: rgba(0, 0, 0, 0.54);
                        }

                        .members {
                            margin-top: 16px;
                        }
                    }
                }
            }
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

    #profile {

        .about-tab {

            .about-sidebar {
                padding: 8px
            }
        }
    }

}

*/