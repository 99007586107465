#customer-event {
	.content {
		.simple-table-container {
			.table-title {
				font-size: 18px;
				padding: 16px;
			}
		} 
		
		.graph-title {
			font-size: 18px;
			padding: 16px;
		}
	}
	
}