#deltacontracts {
	
	.top-toolbar {
        padding: 12px 16px 15px 16px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }
	
	.daterange-wrapper {
        
        position: relative;
        min-width: 256px;
        
        .daterange {
            height: 34px;
            line-height: 34px;
            padding: 5px;
            font-weight: 500;

            .icon {
                color: rgb(33,33,33);
            }

            input {
                background: #fafafa;
                padding-left: 12px;
                height: 34px;
                color: rgb(33,33,33);
				font-size:100%;
            }
            
        }
        
    }
	
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {
	#deltacontracts {
		
	}
}