#measurements {
	.top-toolbar {
        padding: 12px 16px 15px 16px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }
	
	.daterange-wrapper {
        
        position: relative;
        min-width: 256px;
        
        .daterange {
            height: 34px;
            line-height: 30px;
            padding: 5px;
            font-weight: 500;

            .icon {
                color: rgb(33,33,33);
            }

            input {
                background: #fafafa;
                padding-left: 12px;
                height: 34px;
                color: rgb(33,33,33);
				font-size:100%;
            }
            
        }
        
    }
	
	.dvn-wrapper {
		.md-select-value {
			border: none;
			background-color: #fafafa;
			padding-left: 12px;
		}
	}
	
	
	
}

/* Extra small devices (phones, less than 768px) */
@media (max-width:767px) {
	.daterangepicker {
		margin: 10px;
		font-size: 5px;
		
		&.show-calendar .ranges {
			display: none;
		}
		
		.ranges li {
			font-size: 12px;
			padding: 10px 12px;
			cursor: pointer;
		}
		.drp-selected {
			display: none;
		}
		
		.drp-calendar.left,
		.drp-calendar.right {
			padding: 0 5px 0 5px;
		}
	}
	
	
}