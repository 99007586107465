#fabriccontracts {
	.content {
		border:1px solid red;
		position:absolute;
		top:50px;
		left:0;
		right:0;
		bottom:0;
		padding:0;
		
		.fabric-iframe{
			border:1px solid green;
			width:100%;
			height:100%;
			//height: calc(100% - 64px)
		}
		
	}
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

	#fabriccontracts {
		
	}
}