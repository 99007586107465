/* .custom-icon-button-wrapper {
  margin: 6px;
  line-height: 56px;
  min-width: 0;
  width: 56px;
  height: 56px;
  vertical-align: middle;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 50%;

  .md-button.md-icon-button {
    margin: 6px 8px;
  }

} */

#gameMonitoring {
  .page-content {
    .progress-button {
      min-width: 44px;
    }

    .md-table-pagination {
      border: 0;
    }

    table.variables-table {

      th:first-child,
      td:first-child {
        padding: 0 8px;
      }
    }

    table:not(.variables-table) {
      thead tr.md-row {
        height: 64px;
      }

      th {
        font-size: 14px;
      }

      th:first-child,
      td:first-child {
        padding: 0 8px;
        width: 96px;
        min-height: 70px;
      }

      th:last-child {
        text-align: center;
        width: 132px;
        white-space: nowrap;
      }

      td:last-child {
        width: 132px;
        white-space: nowrap;
      }
    }

    .manage {
      .ms-stepper {
        .ms-stepper-horizontal {
          background: none;

          .ms-stepper-controls {
            box-shadow: $whiteframe-shadow-1dp;
          }

          .ms-stepper-steps {
            .ms-stepper-step {
              .step-header {

                //border:1px solid green;
                h2,
                h3,
                p {
                  margin: 0;
                }
              }

              .step-content {

                //border:1px solid orange;
                md-card {
                  min-width: 250px;

                  md-card-header {
                    padding: 16px;
                  }

                  md-card-content {
                    &.addCondition {
                      min-height: 150px;
                    }

                    &.addVariable {
                      min-height: 100px;
                    }
                  }

                  md-card-footer {
                    padding: 0;
                  }
                }

                select {
                  border-color: rgba(0, 0, 0, 0.2);
                }

                >div {
                  margin-bottom: 16px;

                  .heading {
                    margin-bottom: 16px;
                    padding: 16px;
                  }
                }
              }

              .tree {
                .jstree-checkbox-disabled {
                  display: none;
                }
              }

              md-radio-group.expireGroup {
                md-radio-button {
                  margin-bottom: 0;
                }
              }

              .notifications {
                md-input-container {
                  margin-bottom: 0;
                  margin-left: 16px;
                }
              }

              .hint {
                position: absolute;
                left: 2px;
                right: auto;
                bottom: 7px;
                /* Copy styles from ng-messages */
                font-size: 12px;
                line-height: 14px;
                transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);

                &.ng-hide,
                &.ng-enter,
                &.ng-leave.ng-leave-active {
                  bottom: 26px;
                  opacity: 0;
                }

                &.ng-leave,
                &.ng-enter.ng-enter-active {
                  bottom: 7px;
                  opacity: 1;
                }
              }

              .small {
                opacity: 0.8;
                font-size: 80%;
              }

              .md-radio-interactive input {
                pointer-events: all;
              }
            }
          }

          .ms-stepper-navigation-wrapper {
            .ms-stepper-navigation {
              background: none;

              .ms-stepper-navigation-item {
                .step {
                  margin-left: 0;
                }

                .title {
                  color: inherit;
                  opacity: 0.5;
                }

                &.current {
                  .title {
                    opacity: 1;
                  }
                }

                &:before,
                &:after {
                  border-bottom: 1px solid #777;
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

// RESPONSIVE
@media screen and (min-width: $layout-breakpoint-md) {
  #rules {
    .page-content {
      .manage {
        .ms-stepper {
          .ms-stepper-horizontal {
            .ms-stepper-steps {
              .ms-stepper-step {
                .step-content {
                  >div {
                    margin-left: 8px;
                    margin-right: 8px;

                    &:first-child {
                      margin-left: 0;
                    }

                    &:last-child {
                      margin-right: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
