/* new created 08/1/20 */
.q_info {
  font-weight: 700;
}
.icon-thumb-up {
  //color: greenyellow;
}
.icon-eye {
  margin-right: 2px;
}

#parent_all {
  position: relative;
}

.question-tag {
  cursor: pointer;
}
.q_title {
  max-width: 300px;
}

.parent_user {
  justify-content: flex-end;
}

#icon_points,
#icon_badges {
  margin-right: 2px;
}
#myInput2,
#myInput {
  background-image: url(../assets/images/icons/searchicon.png);
  background-position: 10px 4px;
  background-repeat: no-repeat;
  width: 100%;
  font-size: 16px;
  padding: 6px 20px 6px 40px;
  border: 1px solid #ddd;
  margin-bottom: 12px;
}
#navigation_title,
#tags_title,
#filter_title {
  border-bottom: 1px solid #ccc;
}
#question_timestamp {
  margin-bottom: 5px;
}
#NewquestionInput {
  width: 100%;
  font-size: 14px;
  padding: 6px 10px 6px 10px;
  border: 1px solid #ddd;
  resize: none;
}

input[type="text"] {
  color: black;
}

tags-input .tags {
  box-shadow: none;
  border: 1px solid lightgray;
}

// placeholders
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-style: italic;
  opacity: 0.4;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
  opacity: 0.4;
}
:-ms-input-placeholder {
  /* IE 10+ */
  font-style: italic;
  opacity: 0.4;
}
:-moz-placeholder {
  /* Firefox 18- */
  font-style: italic;
  opacity: 0.4;
}

/* Extra small devices (phones, less than 768px) */
@media (max-width: 767px) {
  #q_avatar {
    display: none;
  }
  .parent_user {
    font-size: 0.9rem;
  }
  .breadcrumb {
    font-size: 0.9rem;
  }
}
/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .layout-row {
  }
}
/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .layout-row {
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .layout-row {
  }
}

.myButton {
  background-color: #44c767;
  border-radius: 2 2px;
  border: 1px solid #18ab29;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 15px;
  padding: 8px 8px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #2f6627;
}
.myButton:hover {
  background-color: #a9f384;
}
.myButton:active {
  position: relative;
  top: 1px;
}

#test {
  margin-top: 25px;
  font-size: 21px;
  text-align: center;
  animation: fadein 1s;
  animation: fadeout 0s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*
#forumdiv {
    position: absolute;
    //top: 0px;
    right: 0;
    //bottom: 0;
    left: 0;
}
#myInput {
    background-image: url(../assets/images/icons/searchicon.png);
    background-position: 10px 4px;
    background-repeat: no-repeat;
    width: 100%;
    font-size: 16px;
    padding: 6px 20px 6px 40px;
    border: 1px solid #ddd;
    margin-bottom: 12px;
  }

  #questionInput {
    background-image: url(../assets/images/icons/questionicon.png);
    background-position: 10px 4px;
    background-repeat: no-repeat;
    width: 100%;
    font-size: 14px;
    padding: 6px 20px 6px 40px;
    border: 1px solid #ddd;
    margin-bottom: 12px;
  }

  .filter-tags {
    
}

.filter-tags .tag {
  background: #e9ecef;
  border-radius: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline-block;
  margin: 5px;
  padding: 5px;
}

.filter-tags .tag:hover {
  background: #ccc;
}

.filter-tags .tag.active {
  background: #4285F4;
  color: white;
}

#collaboration .menu .menu-item {
  text-align: center;
}
#collaboration .menu .menu-item a {
  display: block;
  padding: 5px 10px;
}

.item a {
  padding: 5px 10px;
  color: black;
  display: block;
}

.item a.active {
  background: #08d;
  color: white;
}
.block {
  display: block!important;
}

.question-item {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 15px;
  padding-bottom: 0px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.question-item .question-text {
  position: relative;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.question-item h3 {
  font-size: 16px;
  font-weight: bold;
}

a {
  font-weight: 600;
}

a {
  color: #0099FF;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

.workspace-tag-container {
  position: absolute;
  bottom: 5px;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
.question-tag {
  display: inline-block;
  background: #e9ecef;
  color: #6c757d!important;
  padding: 2px 10px;
  border-radius: 5px;
}

.marg-right-5 {
  margin-right: 5px;
}







*/

.container {
  width: 100%;
  background-color: #e9ecef;
  /*padding-right: 0.75rem;
    padding-left: 0.75rem;*/
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

#collaboration {
  background-color: #e9ecef;
  padding-top: 15px;
}

#collaboration .tips-and-tricks-sidebar {
  background: white;
  padding: 10px;
  border-radius: 5px;
}
#collaboration .tips-and-tricks-sidebar .item h3 {
  border-bottom: 1px solid #ccc;
}

.tips-and-tricks-sidebar .item a.active {
  background: #08d;
  color: white;
}

.tips-and-tricks-sidebar .item a {
  padding: 5px 10px;
  color: black;
  display: block;
}

.tabs {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.col-md-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  border: 1px solid #ccc;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.input-group-addon {
  background: #ccc;
  padding: 5px 8px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group .form-control {
  height: auto;
}
.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(2.125rem + 2px);
  padding: 0.3125rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #152a49;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#collaboration .menu .menu-item {
  text-align: center;
}
#collaboration .menu .menu-item a {
  display: block;
  padding: 5px 10px;
}

#dashboard-project {
  /*font-family: delta !important;*/

  md-switch.md-default-theme.md-checked .md-bar,
  md-switch.md-checked .md-bar {
    background-color: rgb(72, 178, 198) !important;
  }

  > .center {
    > .header {
      height: 160px;
      min-height: 160px;
      max-height: 160px;
      padding-bottom: 0;

      .selected-project {
        background: rgba(0, 0, 0, 0.12);
        color: #ffffff;
        padding: 8px 16px;
        height: 40px;
        line-height: 24px;
        font-size: 16px;
      }

      .project-selector {
        margin-left: 1px;
        border-radius: 0;
        background: rgba(0, 0, 0, 0.12);

        md-icon {
          color: #ffffff;
        }
      }
    }

    > .content {
      background: none;
      padding: 0;

      > md-tabs {
        > md-tabs-wrapper {
          background: #ffffff;
          box-shadow: $whiteframe-shadow-1dp;
          padding: 0 24px;

          md-tabs-canvas {
            height: 56px;

            md-pagination-wrapper {
              height: 56px;
            }

            .md-tab {
              padding: 16px 24px;
              text-transform: none;
            }
          }
        }

        &:not(.md-dynamic-height) {
          md-tabs-content-wrapper {
            top: 56px;
          }
        }

        > md-tabs-content-wrapper {
          md-tab-content {
            padding: 12px;
          }
        }
      }
    }
  }

  > .sidenav {
    width: 330px;
    min-width: 330px;
    max-width: 330px;
    padding: 0;
    z-index: 51;
    box-shadow: $whiteframe-shadow-8dp;

    &.md-locked-open {
      width: 250px;
      min-width: 250px;
      max-width: 250px;
      z-index: 2;
      box-shadow: none;
      background: material-color("grey", "200");
    }
  }

  #widgets {
  }
}
