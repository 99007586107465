#plotCard {
    .measureTitle{
        font-style: bold;
        font-size: 20px;
    }
     .measureText{
        font-size: 16px;
    }
    .measureUnit{
         font-style: italic;
    }
}

#plotCardReport,  {
	md-progress-circular {
		top: 50%;
		left: 50%;
		z-index: 10;
		position: absolute;
		
		path {
			stroke: rgb(244,67,55) !important;
		}
		
	}
}
