#bestPractices {
    @import url(https://fonts.googleapis.com/css?family=Lato);

    .faq-heading {
        font-family: Lato;
        font-weight: 400;
        font-size: 19px;
        -webkit-transition: text-indent 0.2s;
        text-indent: 20px;
        color: #333;
    }

    .faq-text {
        font-family: Open Sans;
        font-weight: 400;
        color: #919191;
        width: 95%;
        padding-left: 20px;
        margin-bottom: 30px;
    }

    .faq {
        width: 90%;
        margin: 0 auto;
        background: white;
        border-radius: 4px;
        position: relative;
        border: 1px solid #e1e1e1;
    }
    .faq label {
        display: block;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        height: 56px;
        padding-top: 1px; 
        background-color: #fafafa;
        border-bottom: 1px solid #e1e1e1;
    }

    .faq input[type="checkbox"] {
        display: none;
    }

    .faq .faq-arrow {
        width: 5px;
        height: 5px;
        transition: -webkit-transform 0.8s;
        transition: transform 0.8s;
        transition: transform 0.8s, -webkit-transform 0.8s;
        -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
        border-top: 2px solid rgba(0, 0, 0, 0.33);
        border-right: 2px solid rgba(0, 0, 0, 0.33);
        float: right;
        position: relative;
        top: -30px;
        right: 27px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .faq input[type="checkbox"]:checked + label > .faq-arrow {
        transition: -webkit-transform 0.8s;
        transition: transform 0.8s;
        transition: transform 0.8s, -webkit-transform 0.8s;
        -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    .faq input[type="checkbox"]:checked + label {
        display: block;
        background: rgba(255, 255, 255, 255) !important;
        color: #4f7351;
        height: 150px;
        transition: height 0.8s;
        -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    .faq input[type="checkbox"]:not(:checked) + label {
        display: block;
        transition: height 0.8s;
        height: 60px;
        -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    ::-webkit-scrollbar {
        display: none;
    }
}
