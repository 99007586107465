#dreventsTable {
	table.md-table tbody.md-body > tr.md-row,
	table.md-table tfoot.md-foot > tr.md-row {
		cursor: pointer;
		&:hover {
			background-color: #fafafa;
		}
	}


	table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2),
	table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2) {
		padding: 0 24px 0 0;
	}
}