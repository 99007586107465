#faq {
  @import url(https://fonts.googleapis.com/css?family=Lato);
  .ta-toolbar {
    display: none;
  }
  .ta-scroll-window {
    min-height: 80px;
  }
  .ta-editor {
    border: none;
    border-color: #fafafa;
    height: auto;

    width: 100%;
    max-height: 90px;
    overflow: auto;
    border: 0px solid #ccc;
  }
  #faq_text_angular {
    border: none;
    overflow: auto;
    width: 100%;
    margin-bottom: 5px;
    max-height: 90px;
    height: auto;
    border-color: #fafafa;
    border: 0px solid #ccc;
    border-right: none;
    border-left: none;
  }
  .ta-toolbar.focussed {
    display: none;
  }
  .faq-heading {
    font-family: Lato;
    font-weight: 400;
    font-size: 19px;
    -webkit-transition: text-indent 0.2s;
    text-indent: 20px;
    color: #333;
  }

  .faq-text {
    font-family: Open Sans;
    font-weight: 400;
    color: #919191;
    width: 90%;
    padding-left: 20px;
    margin-bottom: 30px;
  }

  .faq {
    width: 90%;
    margin: auto;
    height: auto;
    margin-top: 10px;
    background: white;
    word-wrap: break-word;
    border-radius: 4px;
    position: relative;
    min-height: 60px;
    overflow: hidden;
    border: 1px solid #e1e1e1;
  }
  .faq label {
    display: block;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    height: 56px;
    padding-top: 1px;
    background-color: #fafafa;
    border-bottom: 1px solid #e1e1e1;
  }

  .faq input[type='checkbox'] {
    display: none;
  }

  .faq .faq-arrow {
    width: 5px;
    height: 5px;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    border-top: 2px solid rgba(0, 0, 0, 0.33);
    border-right: 2px solid rgba(0, 0, 0, 0.33);
    float: right;
    position: relative;
    top: -30px;
    right: 27px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .faq input[type='checkbox']:checked + label > .faq-arrow {
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  .faq input[type='checkbox']:checked + label {
    display: block;
    background: rgba(255, 255, 255, 255) !important;
    color: #4f7351;
    height: auto;
    min-height: 120px;
    transition: height 0.5s;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .faq input[type='checkbox']:not(:checked) + label {
    display: block;
    transition: height 0.5s;
    min-height: 60px;
    overflow: hidden;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  ::-webkit-scrollbar {
    display: none;
  }
}
