#dremulator {
  height: 100%;
  /*background: url('/assets/images/backgrounds/march.jpg') no-repeat;*/
  background-size: cover;
}

.flex-input {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
