#measurementsCard {
	
}

/* Extra small devices (phones, less than 768px) */
@media (max-width:767px) {
    #measurementsCard {
        font-size: 0.8rem;
    }
}

/* Small devices (tablets, 768px and up) */
@media (min-width:768px) {
    #measurementsCard {
        font-size: 0.9rem;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width:992px) {
  #measurementsCard {
      font-size: 0.9rem;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width:1200px) {
  #measurementsCard {
      font-size: 1.3rem;
  }
}