#selfportfolio {
  .content {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;

    .selfportfolio-iframe {
      border: none;
      width: 100%;
      height: 100%;
      //height: calc(100% - 64px)
    }
  }
}

.dvn-selection {
  padding-left: 20px;
  md-input-container {
    border-radius: 5px;
    background-color: rgb(241, 241, 241);
  }
}
