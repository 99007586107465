#controlDevice {
  // border: 1px solid red;
  background-color: white;
  .seperator {
    height: 32px;
    width: 1px;
    background: rgba(0, 0, 0, 0.12);
    margin: 0 8px;
  }
}

.p-8 {
  // border: 1px solid blue;
}
.p-8 > * {
  // border: 1px solid red;
}

.mr-8 {
  // border: 1px solid grey;
  float: left;
}

.dev-title {
  // border: 1px solid grey;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;

  .status {
    margin-left: auto; // push to flex-end
  }
}

.dev-title > * {
  // border: 1px solid red;
}

.dev-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .control {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
}

md-select {
  text-align: right;
}

md-select:focus {
  outline: none;
}

md-select:disabled {
  border-bottom: none !important;
}

.pointer {
  cursor: pointer !important;
  color: darkgrey !important;
}

.cursor-def {
  cursor: default;
}

/*
.p-16 {
  margin: 15px 15px 0 15px;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  box-shadow: 1px 1px #f6f6f6;
  background-color: #f6f6f6;
}
*/
