#treeDiagram {

	// .svg-content {
	// 	display: inline-block;
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// }
	
	#tree-wrapper { 

	// 	.svg-container {
	// 	display: inline-block;
	// 	position: relative;
	// 	width: 100%;
	// 	padding-bottom: 100%; /* aspect ratio */
	// 	vertical-align: top;
	// 	overflow: hidden;
	// }

		#tree-plot {

			.wrapper {
				.md-select-value {
					border: none;
					background-color: #fafafa;
					padding-left: 12px;
				}
			}


			.node circle {
				fill: #fff;
				stroke: steelblue;
				stroke-width: 3px;
			}
			
			.node text {
				font: 12px sans-serif;
			}
			
			.link {
				fill: none;
				stroke: #ccc;
				stroke-width: 5px;
			}

			.legend rect {
				fill:white;
				stroke:black;
				opacity:0.8;
			} 


			.legend {
            padding: 5px;
            font: 10px sans-serif;
            background: yellow;
            box-shadow: 2px 2px 1px #888;
        }
				
		}
	}
	
}