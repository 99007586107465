#dvnsInfo {
	
	.green {
		background-color: rgb(43,180,151);
	}
	.orange {
		background-color: rgb(255,134,79);
	}
	.red {
		background-color: rgb(255,84,106);
	}
	.grey {
		background-color: rgb(224,224,224);
	}
	
}