#mapCard {
	
	.angular-google-map,
	.angular-google-map-container {
		width: 100%;
		height: 100%;
	}
}

	
