/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
@import "../../bower_components/datatables-responsive/css/responsive.dataTables.scss";
// endbower

/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "core/scss/global.scss";
@import "core/directives/ms-card/templates/template-1/template-1.scss";
@import "core/directives/ms-card/templates/template-10/template-10.scss";
@import "core/directives/ms-card/templates/template-2/template-2.scss";
@import "core/directives/ms-card/templates/template-4/template-4.scss";
@import "core/directives/ms-card/templates/template-3/template-3.scss";
@import "core/directives/ms-card/templates/template-5/template-5.scss";
@import "core/directives/ms-card/templates/template-6/template-6.scss";
@import "core/directives/ms-card/templates/template-7/template-7.scss";
@import "core/directives/ms-card/templates/template-8/template-8.scss";
@import "core/directives/ms-card/templates/template-9/template-9.scss";
@import "core/directives/ms-stepper/templates/horizontal/horizontal.scss";
@import "core/directives/ms-stepper/templates/vertical/vertical.scss";
@import "core/theme-options/theme-options.scss";
@import "core/directives/ms-card/ms-card.scss";
@import "core/directives/ms-form-wizard/ms-form-wizard.scss";
@import "core/directives/ms-material-color-picker/ms-material-color-picker.scss";
@import "core/directives/ms-nav/ms-nav.scss";
@import "core/directives/ms-navigation/ms-navigation.scss";
@import "core/directives/ms-responsive-table/ms-responsive-table.scss";
@import "core/directives/ms-scroll/ms-scroll.scss";
@import "core/directives/ms-search-bar/ms-search-bar.scss";
@import "core/directives/ms-splash-screen/ms-splash-screen.scss";
@import "core/directives/ms-stepper/ms-stepper.scss";
@import "core/directives/ms-widget/ms-widget.scss";
@import "core/directives/ms-timeline/ms-timeline.scss";
@import "toolbar/toolbar.scss";
@import "directives/active-games/active-games.scss";
@import "directives/active-goals/active-goals.scss";
@import "directives/comfortzone/comfortzone.scss";
@import "directives/contractdetails/contractdetails.scss";
@import "directives/createcontract/createcontract.scss";
@import "directives/contracttempl/contracttempl.scss";
@import "directives/createsignal/createsignal.scss";
@import "directives/control-device/control-device.scss";
@import "directives/drevents/drevents.scss";
@import "directives/drevents-dvn-table/drevents-dvn-table.scss";
@import "directives/drevents-table/drevents-table.scss";
@import "directives/dvnssummary/dvnssummary.scss";
@import "directives/dvns-info/dvns-info.scss";
@import "directives/dvnsummary/dvnsummary.scss";
@import "directives/feidname/feidname.scss";
@import "directives/feid-control/feid-control.scss";
@import "directives/feidsector/feidsector.scss";
@import "directives/feidssummary/feidssummary.scss";
@import "directives/feidstatus/feidstatus.scss";
@import "directives/feidsummary/feidsummary.scss";
@import "directives/hvac/hvac.scss";
@import "directives/forum-card/forum-card.scss";
@import "directives/intervals/intervals.scss";
@import "directives/latest-points/latest-points.scss";
@import "directives/lighting/lighting.scss";
@import "directives/measurements-card/measurements-card.scss";
@import "directives/measurements-card-kpis/measurements-card-kpis.scss";
@import "directives/numeric-card/numeric-card.scss";
@import "directives/optimization/optimization.scss";
@import "directives/overviewdvn/overviewdvn.scss";
@import "directives/overviewdvns/overviewdvns.scss";
@import "directives/plotchartfocus/plotchartfocus.scss";
@import "directives/plot-card/plot-card.scss";
@import "directives/plotchart/plotchart.scss";
@import "directives/plotchartmqtt/plotchartmqtt.scss";
@import "directives/plotchartmqttkpis/plotchartmqttkpis.scss";
@import "directives/plotchartmqttpvforecast/plotchartmqttpvforecast.scss";
@import "directives/plotdvnchart/plotdvnchart.scss";
@import "directives/plotlinechart/plotlinechart.scss";
@import "directives/profit/profit.scss";
@import "directives/profit_dashboard/profit_dashboard.scss";
@import "directives/radialTree/radialTree.scss";
@import "directives/resulteddvns/resulteddvns.scss";
@import "directives/resultedfeids/resultedfeids.scss";
@import "directives/rewards-card/rewards-card.scss";
@import "directives/signalcreate/signalcreate.scss";
@import "directives/signaldetails/signaldetails.scss";
@import "directives/signaldvns/signaldvns.scss";
@import "directives/signals/signals.scss";
@import "directives/smartcontract/smartcontract.scss";
@import "directives/smartcontracts/smartcontracts.scss";
@import "directives/timeline/timeline.scss";
@import "directives/todays_dr_events/todays_dr_events.scss";
@import "directives/todaysloadforecast/todaysloadforecast.scss";
@import "directives/tradingactivated/tradingactivated.scss";
@import "directives/tradingenergy/tradingenergy.scss";
@import "directives/tree-diagram/tree-diagram.scss";
@import "directives/verticalbars/verticalbars.scss";
@import "main/pages/coming-soon/coming-soon.scss";
@import "main/pages/profile/profile.scss";
@import "main/apps/FAQ/FAQ.scss";
@import "main/apps/contract/contract.scss";
@import "main/apps/gamification_customer/gamification_customer.scss";
@import "main/apps/gamification/gamification.scss";
@import "main/apps/trading/trading.scss";
@import "main/apps/userManagement/userManagement.scss";
@import "main/apps/vouchers/vouchers.scss";
@import "navigation/layouts/horizontal-navigation/navigation.scss";
@import "navigation/layouts/vertical-navigation/navigation.scss";
@import "toolbar/layouts/content-with-toolbar/toolbar.scss";
@import "toolbar/layouts/horizontal-navigation/toolbar.scss";
@import "main/pages/auth/forgot-password/forgot-password.scss";
@import "main/pages/auth/lock/lock.scss";
@import "main/pages/auth/reset-password/reset-password.scss";
@import "main/pages/auth/login/login.scss";
@import "main/pages/auth/register/register.scss";
@import "main/pages/errors/403/error-403.scss";
@import "main/pages/errors/404/error-404.scss";
@import "main/apps/FAQ/FAQEdit/FAQEdit.scss";
@import "main/apps/FAQ/FAQNew/FAQNew.scss";
@import "main/apps/collaboration/DELTANews/DELTANews.scss";
@import "main/apps/collaboration/DELTANewsSpecific/DELTANewsSpecific.scss";
@import "main/apps/collaboration/DRBestPractices/DRBestPractices.scss";
@import "main/apps/collaboration/DRBestPracticesSpecific/DRBestPracticesSpecific.scss";
@import "main/apps/collaboration/chat/chat.scss";
@import "main/apps/collaboration/forum/forum-project.scss";
@import "main/apps/collaboration/gamification/gamification.scss";
@import "main/apps/collaboration/offers/offers.scss";
@import "main/apps/collaboration/gamificationSpecific/gamificationSpecific.scss";
@import "main/apps/collaboration/generalDiscussion/generalDiscussion.scss";
@import "main/apps/collaboration/generalDiscussionSpecific/generalDiscussionSpecific.scss";
@import "main/apps/gamification_customer/leaderboard/leaderboard.scss";
@import "main/apps/gamification/gameMonitoring/gameMonitoring.scss";
@import "main/apps/gamification/gameEngine/gameEngine.scss";
@import "main/apps/gamification/leaderboard/leaderboard.scss";
@import "main/apps/gamification/vouchers/vouchers.scss";
@import "main/apps/historical/customer-events/customer-events.scss";
@import "main/apps/historical/customer-event/customer-event.scss";
@import "main/apps/historical/event/event.scss";
@import "main/apps/historical/gaming/gaming.scss";
@import "main/apps/historical/events/events.scss";
@import "main/apps/historical/historicalenergymarkets/historicalenergymarkets.scss";
@import "main/apps/historical/kpis/kpis.scss";
@import "main/apps/historical/measurements/measurements.scss";
@import "main/apps/historical/logging_service/logging_service.scss";
@import "main/apps/historical/usercontract/usercontract.scss";
@import "main/apps/logging/logging_service/logging_service.scss";
@import "main/apps/overview/analytics/analytics.scss";
@import "main/apps/overview/customers/customers.scss";
@import "main/apps/overview/dashboard/dashboard.scss";
@import "main/apps/overview/dvn/dvn.scss";
@import "main/apps/overview/dvns/dvns.scss";
@import "main/apps/overview/feid/feid.scss";
@import "main/apps/overview/loadcontrol/loadcontrol.scss";
@import "main/apps/portfolio/aggregatedbaselineforecast/aggregatedbaselineforecast.scss";
@import "main/apps/portfolio/baselineforecast/baselineforecast.scss";
@import "main/apps/portfolio/dremulator/dremulator.scss";
@import "main/apps/portfolio/clustering/clustering.scss";
@import "main/apps/portfolio/flexibilityforecasting/flexibilityforecasting.scss";
@import "main/apps/portfolio/gridengine/gridengine.scss";
@import "main/apps/portfolio/optimaldispatch/optimaldispatch.scss";
@import "main/apps/portfolio/priceforecasting/priceforecasting.scss";
@import "main/apps/portfolio/segmentation/segmentation.scss";
@import "main/apps/portfolio/selfportfolio/selfportfolio.scss";
@import "main/apps/security/analytics/analytics.scss";
@import "main/apps/security/fabriccontracts/fabriccontracts.scss";
@import "main/apps/security/tradeOffTool/trade_off_tool.scss";
@import "main/apps/smartcontracts/deltacontract/contract.scss";
@import "main/apps/smartcontracts/deltacontracts/deltacontracts.scss";
@import "main/apps/smartcontracts/fabriccontracts/fabriccontracts.scss";
@import "main/apps/smartcontracts/intervals/intervals.scss";
@import "main/apps/smartcontracts/signal/signal.scss";
@import "main/apps/smartcontracts/signals/signals.scss";
@import "main/pages/profile/tabs/userinfo/userinfo.scss";
@import "main/pages/profile/tabs/usersettings/usersettings.scss";
@import "main/apps/gamification/dialogs/editAction/edit-action.scss";
@import "main/apps/gamification/dialogs/editAward/edit-award.scss";
@import "main/apps/gamification/dialogs/newAction/new-action.scss";
@import "main/apps/gamification/dialogs/newAward/new-award.scss";
// endinjector

/* nd-user-nav */
.nd-user-nav {
	position: fixed;
	top: 0;
	width: 100%;
	/*height: 120px;*/
	background-color: white;
	background-image: none;
	background-repeat: no-repeat;
	z-index: 40;
}

@media (min-width: 599px) and (max-width: 1279px) {
	.nd-user-nav { 
		background-image: url("assets/images/logos/oi2lab.svg");
	}
}
@media (min-width: 1280px) {
	.nd-user-nav {
		background-image: url("assets/images/logos/oi2lab.svg");
	}
}
.nd-user-nav .upper-row {
	height: 56px;
}
.nd-user-nav .upper-row .menu-container {
	margin-top: 18px;
}
.nd-user-nav .upper-row .menu-container .lang-container {
	float: right;
}
.nd-user-nav .upper-row .menu-container .badge-attention {
	font-size: 16px;
	margin-left: 12px;
	margin-top: -25px;
	z-index: -1000;
	color: red;
}
.notification-badge {
	position: relative;
}
.notification-badge[data-badge-count]:after {
	background:red;
	border-radius: 7px;
	color: white;
	content: attr(data-badge-count);
	font-size: 8px;
	font-weight: 600;
	line-height: 13px;
	padding: 0 3px;
	position: absolute;
	width: 8px;
	text-align: center;
	top: -6px;
	right: 2px;
}
.nd-user-nav md-input-container:not(.md-input-invalid).md-input-focused md-icon {
	color: white;
}
.nd-user-nav .search-input {
	background-color: white;
	color: black;
	font-size: 16px;
}
.nd-user-nav md-menu-content {
	height: 100px;
}
.messages-menu-item {
	height: 340px;
	max-height: 340px;
	overflow-y: auto;
}
.messages-menu-item .msg-item {
	font-size: 14px;
}
.messages-menu-item .msg-item.unread {
	background-color: lightgoldenrodyellow;
}
.notification-menu-item {
	height: 340px;
	max-height: 340px;
	overflow-y: auto;
	max-width: 384px;
}
.notification-menu-item .notification-item {
	font-size: 14px;
}
.notification-menu-item .notification-item.unread {
	background-color: lightgoldenrodyellow;
}
.messages-menu-content {
	height: 340px;
	max-height: 340px;
}
.nd-user-nav .user-data {
	margin-top: 24px;
}
.friend-request-menu-item {
	height: 80px;
}
.friend-request-container {
	font-size: 12px;
	margin-top: 8px;
	margin-bottom: 8px;
}
.friend-request-container .user-name {
	font-size: 14px;
	margin-bottom: 3px;
}
.friend-request-container .request-date {
	font-size: 10px;
}
.friend-request-container .link-btn {
	font-size: 12px;
	text-transform: uppercase;
	cursor: pointer;
	margin: 1px 20px 0 0;
}
.friend-request-container .link-btn:hover {
	text-decoration: underline;
}
.friend-request-container .user-portrait {
	border-radius: 50%;
	overflow: hidden;
	border: 1px solid rgba(249,249,249,1);
	height: 64px;
	width: 64px;
}
.nd-user-nav .user-portrait {
	overflow: hidden;
	width: 44px;
	height: 44px;
	border-radius: 50%;
	border: 1px solid rgba(249,249,249,1);
	display: block;
	margin: -10px 10px 0 auto;
	cursor: pointer;
	outline: none;
}
.nd-user-nav .user-portrait > img,
.friend-request-container .user-portrait > img {
	display: block;
	height: 100%;
	width: 100%;
}
.nd-user-nav .menu-button {
	text-transform: capitalize;
	font-size: 18px;
}
.nd-user-nav .grid-list {
	width: 100%;
	margin-top: 12px;
}
.nd-user-nav .grid-list md-icon {
	width: 48px;
	height: 48px;
	margin-top: -5px;
}
.nd-user-nav .grid-list md-grid-tile {
	cursor: pointer;
}
.nd-user-nav .grid-list md-grid-tile:focus {
	outline: none;
}
.nd-user-nav .grid-list md-grid-tile-footer {
	height: 18px;
	font-size: 14px;
	text-align: center;
	width: 100%;
	color: black;
	background-color: white;
}
.nd-user-nav .grid-list md-grid-tile-footer figcaption {
	width: 100%;
}

@media (min-width: 600px) and (max-width: 829px) {
	.nd-user-nav .grid-list .item-name {
		font-size: 12px;
	}
}
@media (min-width: 830px) and (max-width: 959px) {
	.nd-user-nav .grid-list .item-name {
		font-size: 14px;
	}
}
@media (min-width: 960px) and (max-width: 1279px) {
	.nd-user-nav .grid-list .item-name {
		font-size: 14px;
	}
}
.nd-user-nav .grid-list .menu-item-active {
	background-color: transparent;
	color: white;
}
.nd-user-nav .divider-strip {
	height: 5px;
	background-color: rgb(103,58,183);
}