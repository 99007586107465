$battery-icon-size: 54px;
$battery-icon-size-medium: 40px;

.search { 
  padding:6px 15px 6px 30px;
  margin:3px; 
  background: url('../../../assets/images/icons/search.png') no-repeat 8px 6px; 
}

.rounded { 
  border-radius:15px; 
  -moz-border-radius:15px; 
  -webkit-border-radius:15px; 
}

.grey {
  background-color:rgb(233,236,239);
}

.bt {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.tooltip-multiline {
  height: auto;
}

md-tooltip .md-content {
  height: auto !important;
  max-width: 300px !important;
  font-size: 11px !important;
  border-radius:10px;
  padding:10px;
  line-height: normal;
}

md-tooltip {
  height: auto !important;
  max-width: 300px !important;
  font-size: 11px !important;
  overflow: visible !important;
  white-space: normal !important;
  padding:10px;
}

md-tooltip ._md-content {
  height: auto !important;
  max-width: 300px !important;
  font-size: 11px !important;
  padding:10px;
}

form{
  width:500px;
  margin:0 auto;
}

input[type=text]{
  color:#bcbcbc;
}

// .jstree-default .jstree-clicked {
//   background: blue;
// }

input[type=button], input[type=button]:hover {
  position:relative; 
  left:-6px;
  border:1px solid #adc5cf;
  background: #e4f1f9; /* Old browsers */
  background: -moz-linear-gradient(top, #e4f1f9 0%, #d5e7f3 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#e4f1f9), color-stop(100%,#d5e7f3)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #e4f1f9 0%,#d5e7f3 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #e4f1f9 0%,#d5e7f3 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #e4f1f9 0%,#d5e7f3 100%); /* IE10+ */
  background: linear-gradient(top, #e4f1f9 0%,#d5e7f3 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e4f1f9', endColorstr='#d5e7f3',GradientType=0 ); /* IE6-9 */
  color:#7da2aa;
  cursor: pointer;
}

.buttondemoBasicUsage section {
  background: #f7f7f7;
  border-radius: 3px;
  text-align: center;
  margin: 1em;
  position: relative !important;
  padding-bottom: 10px; }
.buttondemoBasicUsage md-content {
  margin-right: 7px; }
.buttondemoBasicUsage section .md-button {
  margin-top: 16px;
  margin-bottom: 16px; }
.buttondemoBasicUsage .label {
  position: absolute;
  bottom: 5px;
  left: 7px;
  font-size: 14px;
  opacity: 0.54; }

.lighter{
  background: url('./images/lighter_grey.jpg');
}
.lighter input[type=text]{
  border:1px solid #d0d0d0;
  background-color:#fcfcfc;
}

//md-switch.md-default-theme.md-checked .md-thumb, md-switch.md-checked .md-thumb {
//  background-color:white;
//}

//md-switch.md-default-theme.md-checked .md-bar, md-switch.md-checked .md-bar .lighting {
//  background-color:rgb(255,192,78) !important;
//}

.at-multirange-slider {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    color: #999999;
    height: 1em;
    padding-top: .5em;
  }
  
  .at-multirange-slider .slider {
    border: 1px solid silver;
    height: 0.5em;
    position: relative;
  }
  
  .at-multirange-slider .slider-range {
    box-sizing: border-box;
    display: inline-block;
    height: 100%;
    position: relative;
    vertical-align: top;
  }
  
  .at-multirange-slider .slider-handle {
    border-radius: 1em;
    background: #fff;
    cursor: pointer;
    border: 2px solid gray;
    height: 1em;
    position: absolute;
    right: -0.4em;
    text-align: center;
    top: -0.3em;
    width: 1em;
    z-index: 100;
  }
  
  .at-multirange-slider .slider-handle:hover {
    background: #ddd;
  }
  
  .at-multirange-slider .slider-range:last-child .slider-handle{
      display: none;
  }
  
  .at-multirange-slider .slider-label-top {
    font-size: .75em;
    display: block;
    position: absolute;
    text-align: center;
    top:-1.5em;
    width:100%;
  }
  .at-multirange-slider .slider-label-bottom {
    font-size: .75em;
    display: block;
    line-height: 0.7em;
    position: absolute;
    text-align: center;
    top:1.3em;
    width:100%;
  }
  
  .at-multirange-slider .slider-handle .slider-handle-label {
    font-size: .75em;
    position: absolute;
    right: -0.4em;
    text-align: center;
    top: 3em;
  }
  
  .slider {
      background: #022;
    }
    
  
  .color-range-2 {
    background-color: #fff;
  }
  .color-range-1 {
    background-color: #cecc26;
  }
  .color-range-0 {
    background-color: #fff;
  }
  
  #overview.custom-slider.rzslider .rz-bar {
    background: #ffe4d1;
    height: 2px;
  }
  #overview.custom-slider.rzslider .rz-selection {
    background: rgb(254,126,29);
  }
  
  #overview.custom-slider.rzslider .rz-pointer {
    width: 16px;
    height: 16px;
    top: auto;
    bottom: -4px;
    background-color: rgb(254,126,29);
    // border-top-left-radius: 3px;
    // border-top-right-radius: 3px;
  }
  
  #overview.custom-slider.rzslider .rz-pointer:after {
    display: none;
  }
  
  #overview.custom-slider.rzslider .rz-bubble {
    bottom: 14px;
  }
  
  #overview.custom-slider.rzslider .rz-limit {
    font-weight: bold;
    color: rgb(254,126,29);
  }
  
  #overview.custom-slider.rzslider .rz-tick {
    width: 1px;
    height: 10px;
    margin-left: 4px;
    border-radius: 0;
    background: rgb(254,126,29);
    top: -1px;
  }
  
  #overview.custom-slider.rzslider .rz-tick.rz-selected {
    background: rgb(254,126,29);
  }
/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
    battery-card {
        md-icon {
            font-size: $battery-icon-size-medium!important;
            width: $battery-icon-size-medium!important;
            height: $battery-icon-size-medium!important;
            min-width: $battery-icon-size-medium!important;
            min-height: $battery-icon-size-medium!important;
            line-height: $battery-icon-size-medium!important;
        }
        h2 {
            font-size: 2.2rem;
            span {
                font-size: 1.7rem;
            }
        }
        p {
            font-size: 1.4rem;
        }
        .timestamp{
            font-size: 11px;
        }
    }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 992px) {
    battery-card {
        h2 {
            font-size: 2rem;
            span {
                font-size: 1.5rem;
            }
        }
        p {
            font-size: 1.3rem;
        }
    }
}

