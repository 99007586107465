#clustering {
	.content {
		position:absolute;
		top:50px;
		left:0;
		right:0;
		bottom:0;
		padding:0;
		
		.clustering-iframe{
			border:none;
			width:100%;
			height:100%;
			//height: calc(100% - 64px)
		}
		
	}
}