#error-404 {
    #error-404-wrapper {
        flex: 1 0 auto;

        #error-404-content {
            width: 384px;
            max-width: 384px;
			 padding: 32px;
            background: #FFFFFF;
            text-align: center;

            .logo {
                margin: 16px auto;
            }

			.error-code {
				font-size: 48px;
				text-align: center;
				line-height: 1;
				margin-bottom: 16px;
				font-weight: 500;
				color: rgba(0, 0, 0, 0.7);
			}

			.message {
				font-size: 14px;
				text-align: center;
				color: rgba(0, 0, 0, 0.54);
			}

			.search {
				width: 100%;
				height: 56px;
				line-height: 56px;
				margin: 48px auto 16px auto;
				padding: 16px;
				background: #FFFFFF;

				input {
					padding: 0 0 0 16px;
				}
			}

			.back-link {
				font-size: 15px;
				text-align: center;
			}
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    #error-404 {

        #error-404-wrapper {
            padding: 16px;

            #error-404-content {
                padding: 24px;
                width: 100%;
            }
        }
    }

}



