$battery-icon-size: 54px;
$battery-icon-size-medium: 40px;

#sliderX .custom-slider.rzslider .rz-bar  {
  background: #ffe4d1;
  height: 2px;
}
#sliderX .custom-slider.rzslider .rz-selection {
  background: rgb(255,192,78)
}

#sliderX .custom-slider.rzslider .rz-pointer {
  width: 16px;
  height: 16px;
  top: auto;
  bottom: -4px;
  background-color: rgb(255,192,78);
  // border-top-left-radius: 3px;
  // border-top-right-radius: 3px;
}

#sliderX .custom-slider.rzslider .rz-pointer:after {
  display: none;
}

#sliderX .custom-slider.rzslider .rz-bubble {
  bottom: 14px;
}

#sliderX .custom-slider.rzslider .rz-limit {
  font-weight: bold;
  color: rgb(255,192,78);
}

#sliderX .custom-slider.rzslider .rz-tick {
  width: 1px;
  height: 10px;
  margin-left: 4px;
  border-radius: 0;
  background: rgb(255,192,78);
  top: -1px;
}

#sliderX .custom-slider.rzslider .rz-tick.rz-selected {
  background: rgb(255,192,78);
}

// .rzslider .rz-pointer.rz-active:after
// {
//   background-color: #ffc04e;
// }

// .rzslider .rz-pointer:after
// {
//   background-color: #ffc04e;
// }

// .rzslider .rz-pointer:hover:after
// {
//   background-color: #ffc04e;
// }

// .rzslider .rz-pointer {
//   width:12px;
//   height:12px;
//   top: -4px;
// }

.at-multirange-slider {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    color: #999999;
    height: 1em;
    padding-top: .5em;
  }
  
  .at-multirange-slider .slider {
    border: 1px solid silver;
    height: 0.5em;
    position: relative;
  }
  
  .at-multirange-slider .slider-range {
    box-sizing: border-box;
    display: inline-block;
    height: 100%;
    position: relative;
    vertical-align: top;
  }
  
  .at-multirange-slider .slider-handle {
    border-radius: 1em;
    background: #fff;
    cursor: pointer;
    border: 2px solid gray;
    height: 1em;
    position: absolute;
    right: -0.4em;
    text-align: center;
    top: -0.3em;
    width: 1em;
    z-index: 100;
  }
  
  .at-multirange-slider .slider-handle:hover {
    background: #ddd;
  }
  
  .at-multirange-slider .slider-range:last-child .slider-handle{
      display: none;
  }
  
  .at-multirange-slider .slider-label-top {
    font-size: .75em;
    display: block;
    position: absolute;
    text-align: center;
    top:-1.5em;
    width:100%;
  }
  .at-multirange-slider .slider-label-bottom {
    font-size: .75em;
    display: block;
    line-height: 0.7em;
    position: absolute;
    text-align: center;
    top:1.3em;
    width:100%;
  }
  
  .at-multirange-slider .slider-handle .slider-handle-label {
    font-size: .75em;
    position: absolute;
    right: -0.4em;
    text-align: center;
    top: 3em;
  }
  
  .slider {
      background: #022;
    }
    
  
  .color-range-2 {
    background-color: #fff;
  }
  .color-range-1 {
    background-color: #cecc26;
  }
  .color-range-0 {
    background-color: #fff;
  }
  

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
    battery-card {
        md-icon {
            font-size: $battery-icon-size-medium!important;
            width: $battery-icon-size-medium!important;
            height: $battery-icon-size-medium!important;
            min-width: $battery-icon-size-medium!important;
            min-height: $battery-icon-size-medium!important;
            line-height: $battery-icon-size-medium!important;
        }
        h2 {
            font-size: 2.2rem;
            span {
                font-size: 1.7rem;
            }
        }
        p {
            font-size: 1.4rem;
        }
        .timestamp{
            font-size: 11px;
        }
    }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 992px) {
    battery-card {
        h2 {
            font-size: 2rem;
            span {
                font-size: 1.5rem;
            }
        }
        p {
            font-size: 1.3rem;
        }
    }
}